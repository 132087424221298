<template>
  <div class="page1">
    <!--停车记录查询-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <!-- <el-form-item label="城市">
            <el-select
              v-model.trim="formInline.areaId"
              @change="areaChange"
              filterable
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.streetId"
              filterable
              @change="
                $refs.parkInput.setValue('');
                formInline.operationId = '';
              "
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
            <el-select v-model.trim="formInline.entryOrExit" filterable size="8">
              <el-option
                :label="item.desc"
                :value="item.code"
                :key="item.code"
                v-for="item in ExitTypeList"
              ></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Entry_time')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Cleaning_time')">
                <timeRangePick
                  @timeChange="timeChange2"
                  ref="timeRangePicker2"
                  :defalutDate="defalutDate2"
                />
              </el-form-item>
              <!--<el-form-item label="是否查询临时停车" >
          	<el-select v-model.trim="formInline.parkType" filterable placeholder="" size="10">
	            <el-option label="全部" value="-1"></el-option>
	            <el-option :label="park.desc" :value="park.code" :key="park.code" v-for="park in parkType"></el-option>
            </el-select>
        </el-form-item>-->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button icon="el-icon-upload2" @click="synCar()">同步</el-button>
            </div>
            <div class="col_right mbd4">
              <el-button
                icon="el-icon-upload2"
                @click="exportFile()"
                v-if="$route.meta.authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>

          <!-- <p style="color: #909399">说明：导出数据最多一次导出10,000条，如果筛选条件范围较大，则只能导出前10,000条。</p> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          stripe
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column type="selection"> </el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 弹窗 -->
      <pic-detail
        :infoSize="4"
        :infoDetail="parkDetail"
        :rowData="rowData"
        :detailFlag="dialogVisible"
        :tableOrder="tableOrder"
        :parkRecordIdTable="parkRecordIdTable"
        @close="dialogVisible = false"
        :total="total1"
        :tableOrderdata="tableOrderdata"
        :parkRecordIdData="parkRecordIdData"
        @getOrder="clildrenHandleCurrentChange"
        :entryPic="entryPic"
        :exitPic="exitPic"
      ></pic-detail>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import picDetail from "@/components/picDetail/picDetailRoadside";
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponentRoadside";
import InfoList from "@/components/infoList/infoList";
export default {
  name: "businessParkRecord",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      multipleSelection: [],
      defalutDate: ["", ""],
      defalutDate2: [],
      flag: false,
      plateNumber: "",
      rowData: {},
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      roleList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "entryTimeStr",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "clearTime",
          label: "清除时间",
          width: "",
        },
        {
          prop: "userName",
          label: "操作员",
          width: "",
        },
        {
          prop: "clientStatus",
          label: "同步状态",
          width: "",
          formatter: (row, column) => {
            if (row.clientStatus == 1) {
              return "同步成功";
            } else {
              return "同步失败";
            }
          },
        },
      ],
      tableOrderrefund: [
        {
          label: "支付来源",
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.payment_time"),
          value: "payTime",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeDesc"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: "已支付金额",
          value: "money",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付流水号",
          value: "paymentId",
        },
      ],
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        // { label: "支付方式", value: "payTypeDesc", width: "" },
        // { label: "支付终端", value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payment_channels"), value: "channelTypeDesc", width: "" },
        { label: this.$t("list.Payment_devices"), value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      //          if (row.money) {
      //            return Number((row.money) / 100).toFixed(2)
      //          } else {
      //            return '0.00'
      //          }
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeName"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      tableData: [],
      parkDetail: [],
      parkRecordId: 0,
      parkRecordIdData: [],
      page: 1,
      size: 15,
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        startTime2: "",
        endTime2: "",
      },
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("multipleSelection", this.multipleSelection);
    },
    synCar() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "success",
          message: "请选中僵尸车",
        });
        return;
      }
      this.$confirm(this.multipleSelection.length + "条记录将被同步，确定同步吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let parkRecordIds = "";
          for (let item of this.multipleSelection) {
            parkRecordIds += item.parkRecordId + ",";
          }
          parkRecordIds = parkRecordIds.substring(0, parkRecordIds.length - 1);
          // console.log("parkRecordIds", parkRecordIds);
          // console.log("clearCar", this.multipleSelection);
          this.syncData(parkRecordIds);
        })
        .catch(() => {});
    },
    syncData(parkRecordIds) {
      this.loading = true;
      this.$axios
        .post("/acb/2.0/bacb/zombieCarClearRecord/sync", {
          data: {
            parkRecordIds: parkRecordIds,
            // operationId: this.$route.query.operationId,
          },
        })
        .then((res) => {
          this.loading = false;
          this.$message({
            type: "success",
            message: res.desc,
          });
          if (res.state == 0) {
            this.searParkRecordList();
          } else {
          }
        })
        .catch(() => {});
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    timeChange2(timeArr) {
      this.formInline.startTime2 = timeArr[0];
      this.formInline.endTime2 = timeArr[1];
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
      };

      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.timeRangePicker.resetTime2(["", ""]);
      this.$refs.timeRangePicker2.resetTime();
      this.$refs.parkInput.setValue();
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
      var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    refundOrder() {
      // GET    "/acb/2.0/refundRecord/list"
      this.$axios
        .get("/acb/2.0/bacb/refundRecord/queryRefundOrderList", {
          data: {
            pageNum: this.page,
            pageSize: this.size,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    plateNumberSelect(item) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    // 选车牌
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    detailShow(data) {
      this.parkRecordId = data.parkRecordId;
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
      //    this.getRefund();
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            areaIds:
              this.formInline.streetId != "" ? this.formInline.streetId : this.formInline.areaId,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 获取车场类型 */
    // getPark() {
    //   let opt = {
    //     url: '/acb/2.0/parkType/list',
    //     method: 'get',
    //     data: {},
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.parkType = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/bacb/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/bacb/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
      });
    },
    showLog() {
      let nowDate = new Date(this.dataTimeRest() + " 23:59:59").getTime();
      if (new Date(this.formInline.endTime).getTime() - nowDate > 0) {
        this.$alert("入场时间范围上限为当天");
        return false;
      }
      if (new Date(this.formInline.endTime2).getTime() - nowDate > 0) {
        this.$alert("清理时间范围上限为当天");
        return false;
      }
      // 清理时间必填
      if (!this.formInline.startTime2 || !this.formInline.endTime2) {
        this.$alert("清理时间不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/operation/nameList",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // GET /zombieCarClearRecord/getList
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/bacb/zombieCarClearRecord/getList",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          entryStartTime: this.formInline.startTime,
          entryEndTime: this.formInline.endTime,
          // areaId:this.formInline.streetId != "" ? this.formInline.streetId : this.formInline.areaId,
          clearStartTime: this.formInline.startTime2,
          clearEndTime: this.formInline.endTime2,

          // areaId: this.formInline.streetId,
          // operationId: this.formInline.operationId,
          // inOutType: this.formInline.entryOrExit,
          // carId: this.formInline.carId,
          // plateNumber: this.formInline.plateNumber,
          // mobile: this.formInline.mobile,
          // parkType: this.formInline.parkType,
        },
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      // if (!this.formInline.carId) {
      //   this.formInline.plateNumber = ''
      //   this.$message.error('请输入并选择车牌')
      //   return
      // }
      let flag = this.showLog();
      if (!flag) return;
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          if (flag) {
            this.loading = true;
            this.flag = false;
            this.$refs.parkInput.setShowVal(this.formInline.parkName);
            this.searParkRecordListFun();
          }
        }
      });
    },
    // 订单记录
    getOrder(pageNum) {
      // GET /payOrder/queryPayRecord    /acb/2.0/payOrder/payOrderByParkRecordId/
      let page = pageNum || 1;
      let opt = {
        url: "/acb/2.0/bacb/payOrder/queryPayRecord/" + this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    //  // 退款订单
    //  getRefund() {
    //    let page = this.pageNum || 1
    //    let opt = {
    //      url: '/acb/2.0/parkRecord/payOrder/' + this.rowData.parkRecordId,
    //      method: 'get',
    //      data: {
    //        page: page,
    //        pageSize: this.pageSize
    //      },
    //      success: (res) => {
    //        if (res.state == 0) {
    //          this.tableOrderdataRefund = res.value.list;
    //          this.total1 = res.value.total * 1 || 0;
    //        } else {
    //          this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //            confirmButtonText: this.$t('pop_up.Determine')
    //          });
    //        }
    //      }
    //    };
    //    this.$request(opt);
    //  },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        //   "/acb/2.0/parkRecord/" + parkRecordId,
        url: "/acb/2.0/bacb/parkRecord/getById/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    // 导出
    exportFile() {
      let opt = {};
      opt = {
        //          page: this.pageNum,
        //	        pageSize: this.pageSize,
        parkId: this.formInline.parkId,
        carId: this.formInline.carId,
        entryStartTime: this.formInline.startTime,
        entryEndTime: this.formInline.endTime,
        clearStartTime: this.formInline.startTime2,
        clearEndTime: this.formInline.endTime2,
      };
      // console.log("opt", opt);
      exportExcelNew("/acb/2.0/bacb/zombieCarClearRecord/export", opt);
    },
  },
  components: {
    // InfoList,
    picDetail,
    myComponent,
    timeRangePick,
  },

  /**
   * 现在是 停车场 名称 ， 商户名称用现在的   车牌号， 城市， 区域，出入场类型 支付方式  用以前的
   */
  created() {
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData()
    this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      // this.formInline.startTime = this.$route.query.bindTime
      //   ? this.$route.query.bindTime
      //   : this.$route.query.createdTime
      //   ? this.$route.query.createdTime
      //   : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      // this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.date1 = this.formInline.startTime;
      this.date2 = this.formInline.endTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1 ? "" : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
  },
  mounted() {
    this.defalutDate2 = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
    this.searParkRecordList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  // height 35px
}

.conborder {
  border-top: 1px solid #C0CCDA;
  background: #FFFFFF;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    // overflow: hidden;
    // padding: 22px 22px;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
